import PhoneInput from 'react-phone-input-2';
import styled from 'styled-components';
import { InputStyle } from './input';

const Phone = styled(PhoneInput)`
  &&& {
    input {
      ${InputStyle};
      padding-left: 48px;
    }

    .flag-dropdown {
      border: 2px solid ${(props) => props.theme.colors.newBorder};
      border-right: 1px solid #cacaca;
      background-color: ${(props) => props.theme.colors.white};
      transition: all 0.2s ease-in-out 0s;
    }

    &:hover .flag-dropdown,
    &:hover input {
      border-color: ${(props) => props.theme.colors.blue};
      border-right: 1px solid #cacaca;
    }
    &:focus-within .flag-dropdown {
      border-color: ${(props) => props.theme.colors.blue};
      border-right: 1px solid #cacaca;
    }
  }
`;

export default Phone;
