/* eslint-disable camelcase */
import { useMutation } from '@apollo/client';
import { useRouter } from 'next/router';
import React from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { CREATE_CONTACT } from '../../../queries/contacts';
import { Mutation } from '../../../types/schema';
import { submitHubSpotForm } from '../../../utils/hubspot';
import logging, { saveUserAfterFormSubmit } from '../../../utils/logging';
import ContactFormTemplate from './template';
import * as gtag from '../../../utils/gtag';
import {
  isLookingForJob as checkIsLookingForJob,
  LOOKING_FOR_JOB_KEY,
} from '../../../utils/object';
import { checkIsHuman, verifyRecaptchaToken } from '../../../utils/recaptcha';
import { useLocalStorage } from 'react-use';
import { hideChat } from '../../../utils/chat';
import { checkValidEmail } from '../../../utils/zeroBounce';

export type FormFields = {
  name: string;
  email: string;
  message: string;
  phone: string;
  companyName?: string;
  companySegment?: string;
  role?: string;
};

export type ContactFormProps = {
  background: 'white' | 'primary';
  hideMessage?: boolean;
  hidePhone?: boolean;
  sendToEmail?: boolean;
  buttonText?: string;
  hubspotFormId?: string;
  hubspotPortalId?: string;
  title?: string;
  description?: string;
  destinationUrl?: string;
  className?: string;
  gtagConversionEvent?: string;
  isCompany?: boolean;
  showTermsCheckBox?: boolean;
  urlOnNewTab?: boolean;
};

const ContactForm: React.FC<ContactFormProps> = ({
  hubspotPortalId,
  hubspotFormId,
  sendToEmail,
  gtagConversionEvent,
  ...props
}) => {
  const router = useRouter();

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<boolean | string>(false);
  const [success, setSuccess] = React.useState(false);
  const [isLookingForJob, setIsLookingForJob] = useLocalStorage(LOOKING_FOR_JOB_KEY, false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const [createContact] = useMutation<Mutation>(CREATE_CONTACT);

  const onSubmit = async (data: FormFields, newsChecked?: boolean) => {
    setError(false);
    setLoading(true);
    saveUserAfterFormSubmit({ name: data.name, email: data.email });

    const { response, validEmail } = await checkValidEmail(data.email);
    if (!validEmail) {
      logging.info('Invalid email on Contact Form', { email: data.email, response });
      setError('email');
      setLoading(false);
      return;
    }

    // if (checkIsLookingForJob(data.message)) {
    //   const lookingForJob = localStorage.getItem('lookingForJob');

    // const redirectUser = () => {
    //   window.alert(
    //     'O único canal por onde contratamos desenvolvedores e estagiários de tecnologia é pela plataforma Programadores em Juiz de Fora. Iremos te direcionar pra lá agora.',
    //   );
    //   window.open('https://programador.emjuizdefora.com/', '_blank');
    //   logging.info('User is looking for job', data);
    // };

    //     if (answer) {
    //       redirectUser();
    //       localStorage.setItem('lookingForJob', 'true');
    //     } else {
    //       logging.info('User is looking for job but rejected the pop up', data);
    //     }
    //   } else {
    //     redirectUser();
    //   }

    //   setLoading(false);
    //   return;
    // }

    if (checkIsLookingForJob(data.message)) {
      setIsLookingForJob(true);
      hideChat();
      logging.info('User is looking for job', data);
      return;
    }

    if (executeRecaptcha) {
      const token = await executeRecaptcha('contact_conversion');
      const verify = await verifyRecaptchaToken(token);

      // Checking if robot
      if (!verify.success) {
        logging.error('[contact_conversion] Recaptcha failed', data);
        return setError('captcha');
      } else if (!checkIsHuman(verify)) {
        logging.error('[contact_conversion] User with low recaptcha score', data);
        return setError('captcha');
      }
    }

    try {
      if (gtagConversionEvent)
        gtag.event({ action: 'conversion', conversionEventId: gtagConversionEvent });
      if (hubspotFormId && hubspotPortalId) {
        const title = document.title?.split(' - ')?.[0] || document.title || props.title || '';
        const utmSource = router.query?.utm_source as string;
        const utmMedium = router.query?.utm_medium as string;
        const utmCampaign = router.query?.utm_campaign as string;

        submitHubSpotForm(
          hubspotFormId,
          hubspotPortalId,
          {
            firstname: data.name,
            email: data.email,
            hs_language: navigator.language.toLowerCase() || router.locale || 'pt-br',
            ...(!props.hideMessage && { message: data.message }),
            ...(!props.hidePhone && { phone: data.phone }),
            ...(utmSource && { utm_source: utmSource }),
            ...(utmMedium && { utm_medium: utmMedium }),
            ...(utmCampaign && { utm_campaign: utmCampaign }),
            ...(props.isCompany && {
              jobtitle: data.role,
              ['0-2/name']: data.companyName,
              ['0-2/segment']: data.companySegment,
            }),
          },
          {
            pageName: title,
            pageUri: window.location.href,
          },
          newsChecked,
        )
          .then((res) => res.json())
          .then((res) => {
            if (res.status === 'error') {
              logging.error('Error on Hubspot submit', {
                error: res.errors,
                errorMessage: res.message,
                data,
                form: {
                  hubspotFormId,
                  hubspotPortalId,
                },
                referrer: document.referrer,
              });
              setError(true);
              setLoading(false);
              setSuccess(false);
            }
          });
      }

      if (sendToEmail) {
        await createContact({
          variables: {
            email: data.email,
            name: data.name,
            phone: data.phone,
            message: data.message,
            formTitle: props.title,
            url: window.location.href,
          },
        });
      }

      const { default: ReactPixel } = await import('react-facebook-pixel');
      ReactPixel.track('Lead');
      ReactPixel.track('Contact');

      logging.info('Form has been submitted successfully', data);

      setLoading(false);
      setSuccess(true);
      if (props.destinationUrl) {
        props.urlOnNewTab
          ? window.open(props.destinationUrl, '_blank')
          : router.push(props.destinationUrl);
      }
    } catch (error) {
      logging.error('Error on ContactForm submit', {
        error,
        errorMessage: (error as { message: string }).message,
        data,
      });
      setError(true);
      setLoading(false);
      setSuccess(false);
    }
  };

  return (
    <ContactFormTemplate
      {...props}
      loading={loading}
      onSubmit={onSubmit}
      error={error}
      locale={router.locale as string}
      sitekey={process.env.NEXT_PUBLIC_API_KEY_CAPTCHA as string}
      success={success}
      setError={(value) => setError(value)}
      isLookingForJob={!!isLookingForJob}
    />
  );
};

export default ContactForm;
