export default {
  title: 'App Masters - Melhor empresa de software para se trabalhar - Juiz de Fora, MG',
  description:
    'Empresa de desenvolvimento de softwares, aplicações web, mobile e cloud, em Juiz de Fora, MG.',
  openGraph: {
    type: 'website',
    locale: 'pt',
    url: `${process.env.NEXT_PUBLIC_SITE_URL}/pt`,
    site_name: 'App Masters',
    images: [
      {
        url: `${process.env.NEXT_PUBLIC_SITE_URL}appmasters-metaimage.png`,
        width: 800,
        height: 600,
        alt: 'Logo da App Masters',
      },
    ],
  },
};
