import React from 'react';
import { useForm } from 'react-hook-form';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import { ContactFormProps, FormFields } from '.';
import logging from '../../../utils/logging';
import Button from '../Button';
import CardsDisplay from '../CardsDisplay';
import { Input, Phone, TextArea, PhoneWithMask } from '../Input';
import { LoadingIcon } from '../LoadingIcon';
import { ContactFormWrapper, FormErrorMessage, LookingForJobText } from './styles';

interface ContactFormTemplateProps extends ContactFormProps {
  onSubmit: (data: FormFields, newChecked?: boolean) => void;
  loading: boolean;
  locale: string;
  error: boolean | string;
  sitekey: string;
  success: boolean;
  setError: (value: boolean) => void;
  isLookingForJob: boolean;
}

const texts = {
  pt: {
    name: 'Nome',
    email: 'E-mail',
    phone: 'Telefone',
    message: 'Mensagem',
    companyName: 'Nome da empresa',
    companySegment: 'Segmento da empresa',
    role: 'Seu cargo',
    send: 'Enviar',
    error: {
      default: 'Ocorreu um erro ao enviar o formulário',
      captcha:
        'Você foi identificado como um robô. Se não for o caso, entre em contato conosco de outras maneiras',
    },
    success: {
      title: 'Mensagem recebida!',
      message: 'Em breve entraremos em contato',
    },
    validation: {
      phone: {
        min: 'Telefone deve conter no mínimo 7 dígitos',
        max: 'Telefone deve conter no máximo 20 dígitos',
      },
    },
  },
  en: {
    name: 'Name',
    email: 'Email',
    phone: 'Phone',
    message: 'Message',
    companyName: 'Company name',
    companySegment: 'Company segment',
    role: 'Your role',
    send: 'Send',
    error: {
      default: 'An error occurred on submitting your answers',
      captcha: 'You were identified as a robot. If this is not the case, contact us by other means',
    },
    success: {
      title: 'Message received!',
      message: 'We will contact you soon',
    },
    validation: {
      phone: {
        min: 'Phone must have at least 7 digits',
        max: 'Phone must contain 20 digits maximum',
      },
    },
  },
};

const ContactFormTemplate: React.FC<ContactFormTemplateProps> = ({
  title,
  description,
  hideMessage,
  hidePhone,
  buttonText,
  loading,
  background,
  locale,
  error,
  success,
  destinationUrl,
  className,
  isCompany,
  onSubmit,
  urlOnNewTab,
  isLookingForJob,
}) => {
  const [checked, setChecked] = React.useState(false);
  const [newsChecked, setNewsChecked] = React.useState(true);

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
      message: '',
      phone: '',
      companySegment: '',
      role: '',
      companyName: '',
    },
  });
  const fields = watch();
  const isEn = locale === 'en';
  const enableSubmitButton = React.useMemo(
    () =>
      fields.name &&
      fields.name !== '' &&
      fields.email &&
      fields.email !== '' &&
      checked === true &&
      (hideMessage || (fields.message && fields.message !== '')) &&
      (hidePhone || (fields.phone && fields.phone !== '')) &&
      (!isCompany || (fields.companyName && fields.role && fields.companySegment)),
    [fields, checked],
  );

  const showTermsCheckBox = React.useMemo(
    () =>
      fields.name &&
      fields.name !== '' &&
      fields.email &&
      fields.email !== '' &&
      (hideMessage || (fields.message && fields.message !== '')) &&
      (hidePhone || (fields.phone && fields.phone !== '')) &&
      (!isCompany || (fields.companyName && fields.role && fields.companySegment)),
    [fields],
  );

  React.useEffect(() => {
    if (showTermsCheckBox) {
      logging.info('Checkbox is available, user should check it', fields);
    }
  }, [showTermsCheckBox]);

  React.useEffect(() => {
    if (enableSubmitButton) {
      logging.info('All fields were filled. This logging is triggered before submit', fields);
    }
  }, [enableSubmitButton]);

  const text = texts[locale as 'pt' | 'en'];

  React.useEffect(() => {
    const input = document.getElementById('phone-mask-input') as HTMLInputElement | null;
    if (input) {
      setValue('phone', input.value);
    }
  }, []);

  if (isLookingForJob)
    return (
      <ContactFormWrapper
        background={background as 'white' | 'primary'}
        className={className}
        inputError={!!errors.phone?.message}
        style={{ textAlign: 'left' }}
      >
        <LookingForJobText>
          <p>
            O único canal por onde contratamos desenvolvedores e estagiários de tecnologia é pela
            plataforma{' '}
            <a href="https://programador.emjuizdefora.com/" target="_blank" rel="noreferrer">
              Programadores em Juiz de Fora
            </a>
            .
            <br />
            <br />
            Não recebemos currículo e também não temos outro canal além deste.
            <br />
            <br />
            Tendo seu perfil (com e-mail confirmado) lá, quando novas vagas forem abertas você
            receberá um e-mail avisando para participar.
          </p>
        </LookingForJobText>
      </ContactFormWrapper>
    );

  return (
    <ContactFormWrapper
      success={success && (!destinationUrl || urlOnNewTab)}
      background={background as 'white' | 'primary'}
      className={className}
      inputError={!!errors.phone?.message}
    >
      {title && <h1>{title}</h1>}
      {description && <p>{description}</p>}
      <div className="content-wrapper">
        <CardsDisplay
          columns={1}
          cards={[
            {
              id: '1',
              title: text.success.title,
              description: text.success.message,
              image: <IoMdCheckmarkCircleOutline size={100} />,
            },
          ]}
          ghost
          imageIsComponent
          className={success && (!destinationUrl || urlOnNewTab) ? 'show' : ''}
        />
        <form
          name="budget-form"
          onSubmit={onSubmit && handleSubmit((data) => onSubmit(data as FormFields, newsChecked))}
          className={success && (!destinationUrl || urlOnNewTab) ? 'hide' : ''}
          autoComplete="on"
        >
          <div className="form-row">
            <div className="input-label">
              <Input
                autoComplete="on"
                placeholder={text.name}
                type="text"
                {...register('name')}
                id="name"
                required
              />
              <label htmlFor="name" className="sr-only">
                {text.name}
              </label>
            </div>
            <div className="input-label">
              <Input
                placeholder={text.email}
                type="email"
                {...register('email')}
                id="email"
                required
              />
              <label htmlFor="email" className="sr-only">
                {text.email}
              </label>
              {((errors.email && errors.email.message) || error === 'email') && (
                <p className="error-message">
                  {errors.email?.message?.toString() ||
                    (isEn ? 'The email provided is not valid' : 'O email informado não é válido')}
                </p>
              )}
            </div>
            {!hidePhone && (
              <div className="input-label">
                {locale === 'pt' ? (
                  <PhoneWithMask
                    placeholder={text.phone}
                    required
                    alwaysShowMask={false}
                    mask={'(99) 99999-999?'}
                    formatChars={{ '9': '[0-9]', '?': '[0-9 ]' }}
                    maskChar={null}
                    name="phone"
                    value={fields.phone}
                    onChange={(e) => setValue('phone', e.target.value)}
                  />
                ) : (
                  <Phone
                    country={locale === 'pt' ? 'br' : 'us'}
                    placeholder={text.phone}
                    inputProps={{ name: 'phone' }}
                    onChange={(value) => setValue('phone', value)}
                  />
                )}
                <input type="tel" hidden {...register('phone')} id="phone-mask-input" name="" />
                <label htmlFor="phone" className="sr-only">
                  {text.phone}
                </label>
                {errors.phone && errors.phone.message && (
                  <p className="error-message">{errors.phone.message}</p>
                )}
              </div>
            )}
          </div>
          {isCompany && (
            <div className="form-row">
              <div className="input-label">
                <Input
                  placeholder={text.companyName}
                  type="text"
                  {...register('companyName')}
                  id="companyName"
                  required
                />
                <label htmlFor="companyName" className="sr-only">
                  {text.companyName}
                </label>
              </div>
              <div className="input-label">
                <Input
                  placeholder={text.companySegment}
                  type="text"
                  {...register('companySegment')}
                  id="companySegment"
                  required
                />
                <label htmlFor="companySegment" className="sr-only">
                  {text.companySegment}
                </label>
              </div>
              <div className="input-label">
                <Input
                  placeholder={text.role}
                  type="text"
                  {...register('role')}
                  id="role"
                  required
                />
                <label htmlFor="role" className="sr-only">
                  {text.role}
                </label>
              </div>
            </div>
          )}
          {!hideMessage && (
            <div className="input-label">
              <TextArea
                placeholder={text.message}
                rows={4}
                {...register('message')}
                id="message"
                required
              />
              <label htmlFor="message" className="sr-only">
                {text.message}
              </label>
            </div>
          )}
          {showTermsCheckBox && (
            <>
              <div className="checkbox-wrapper" aria-live="assertive">
                <label className="checkbox">
                  <input
                    type="checkbox"
                    checked={newsChecked}
                    onChange={(e) => setNewsChecked(e.target.checked)}
                  />{' '}
                  <span className="checkmark" />
                  {isEn ? 'Keep me up to date' : 'Mantenha-me informado de novidades'}
                </label>
              </div>
              <div
                className={`checkbox-wrapper ${!checked ? 'shake-animation' : ''}`}
                aria-live="assertive"
              >
                <label className="checkbox">
                  <input
                    type="checkbox"
                    checked={checked}
                    onChange={(e) => {
                      if (e.target.checked) {
                        logging.info('Checkbox filled', fields);
                      } else {
                        logging.info('Checkbox unfilled', fields);
                      }
                      setChecked(e.target.checked);
                    }}
                  />{' '}
                  <span className="checkmark" />
                  {isEn
                    ? 'By ticking here, you are confirming that you have read and agreed with the '
                    : 'Confirmo que li e aceito os '}
                </label>
                {isEn ? (
                  <a
                    href="https://www.appmasters.io/en/terms-and-conditions"
                    target="_blank"
                    rel="noreferrer"
                  >
                    terms and conditions
                  </a>
                ) : (
                  <a href="https://www.appmasters.io/pt/termos-e-condicoes">termos e condições</a>
                )}
              </div>
            </>
          )}
          <footer>
            <Button
              backgroundColor={background}
              disabled={loading || !enableSubmitButton}
              type={!success ? 'submit' : 'button'}
            >
              {loading && <LoadingIcon />}
              {buttonText || text.send}
            </Button>
          </footer>

          {error === true && <FormErrorMessage id="error">{text.error.default}</FormErrorMessage>}
          {error === 'captcha' && (
            <FormErrorMessage id="error">{text.error.captcha}</FormErrorMessage>
          )}
        </form>
      </div>
    </ContactFormWrapper>
  );
};

export default ContactFormTemplate;
