import React from 'react';
import type { AppProps } from 'next/app';
import { ApolloProvider } from '@apollo/client';
import { useApollo } from '../utils/apollo';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import GlobalStyles from '../styles/global';
import theme from '../styles/theme';
import { LocaleProvider } from '../providers/localeProvider';
import seoPt from '../../next-seo-pt.config';
import seoEn from '../../next-seo-en.config';
import { DefaultSeo } from 'next-seo';
import { useRouter } from 'next/router';
import Head from 'next/head';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-image-lightbox/style.css';
import 'react-phone-input-2/lib/style.css';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { LOOKING_FOR_JOB_KEY } from '../utils/object';
import { hideChat, showChat } from '../utils/chat';
import isPropValid from '@emotion/is-prop-valid';
import Script from 'next/script';
import RollbarProvider from '../providers/RollbarProvider';
import { getRollbar } from '../utils/logging';
import WhatsAppProvider from '../providers/WhatsAppProvider';

/**
 * MyApp
 */
function MyApp({ Component, pageProps }: AppProps) {
  // Check the URL starts with 'http://xxxxx' protocol, if it does then redirect to 'https://xxxxx' url of same resource
  if (process.browser && typeof window !== 'undefined') {
    const httpTokens = /^http:\/\/(.*)$/.exec(window.location.href);
    if (httpTokens) {
      const isLocalhost = httpTokens[1].includes('localhost');
      if (!isLocalhost) window.location.replace('https://' + httpTokens[1]);
    }
  }

  const apolloClient = useApollo(pageProps.initialApolloState);
  const router = useRouter();
  const SEO = router.locale === 'pt' ? seoPt : seoEn;

  function storePathValues() {
    const storage = globalThis?.sessionStorage;
    if (!storage) return;
    // Set the previous path as the value of the current path.
    const prevPath = storage.getItem('currentPath');
    storage.setItem('prevPath', `${prevPath}}`);
    // Set the current path value by looking at the browser's location object.
    storage.setItem('currentPath', globalThis.location.pathname);
  }

  React.useEffect(() => storePathValues, [router.asPath]);

  React.useEffect(() => {
    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init(process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID || '');
        ReactPixel.pageView();

        router.events.on('routeChangeComplete', () => {
          ReactPixel.pageView();
        });

        return () => {
          router.events.off('routeChangeComplete', () => {
            ReactPixel.pageView();
          });
        };
      });
  }, [router.events]);

  const showWppChat = router.locale === 'pt';

  React.useEffect(() => {
    let interval: ReturnType<typeof setInterval>;

    const handleChat = (url: string) => {
      const isLookingForJob = localStorage.getItem(LOOKING_FOR_JOB_KEY);

      if (interval) {
        clearInterval(interval);
      }

      interval = setInterval(() => {
        if (url.includes('trabalhe-conosco')) {
          hideChat();
          clearInterval(interval);
        } else {
          if (!isLookingForJob) {
            showChat(showWppChat);
          }
        }
      }, 500);
    };

    handleChat(router.asPath);

    router.events.on('routeChangeComplete', handleChat);

    return () => {
      router.events.off('routeChangeComplete', handleChat);
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [router.events]);

  React.useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    interval = setInterval(getHs, 1000);

    function getHs() {
      const hs = document.getElementById('hubspot-messages-iframe-container');
      const isLookingForJob = localStorage.getItem(LOOKING_FOR_JOB_KEY);
      if (isLookingForJob === 'true') {
        hideChat();
      }
      if (hs && showWppChat) {
        const style = hs.getAttribute('style') || '';
        hs.setAttribute('style', `${style} display: none !important;`);
        clearInterval(interval as NodeJS.Timeout);
      }
    }

    return () => clearInterval(interval as NodeJS.Timeout);
  }, []);

  const rollbarInstance = getRollbar();

  return (
    <>
      <Script
        type="text/javascript"
        id="hs-script-loader"
        defer
        src={`//js.hs-scripts.com/${process.env.NEXT_PUBLIC_HUBSPOT_TRACKING_ID}.js`}
      />
      <Script
        defer
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
      />
      <Script id="gtag-script" type="text/javascript" defer>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
          page_path: window.location.pathname,
          });
      `}
      </Script>
      {/* linkedIn Tag Script */}
      <Script id="linkedin-script" defer>
        {`
        _linkedin_partner_id = "${process.env.NEXT_PUBLIC_LINKEDIN_PARTNER_ID || ''}";
        window._linkedin_data_partner_ids = 
        window._linkedin_data_partner_ids || [];                             
        window._linkedin_data_partner_ids.push(_linkedin_partner_id);
        (function(l) { 
            if (!l){window.lintrk = function(a,b) 
        {window.lintrk.q.push([a,b])};
        window.lintrk.q=[]} var s = 
        document.getElementsByTagName("script")[0];
        var b = document.createElement("script");
        b.type = "text/javascript";
        b.async = true;
        b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
        s.parentNode.insertBefore(b, s);})(window.lintrk);
      `}
      </Script>
      <Head>
        <noscript>
          {/*eslint-disable-next-line @next/next/no-img-element */}
          <img
            height="1"
            width="1"
            style={{ display: 'none' }}
            alt=""
            src={`https://px.ads.linkedin.com/collect/?pid=${
              process.env.NEXT_PUBLIC_LINKEDIN_PARTNER_ID || ''
            }&fmt=gif`}
          />
        </noscript>
      </Head>
      <DefaultSeo {...SEO} />
      <RollbarProvider instance={rollbarInstance}>
        <ThemeProvider theme={theme}>
          <StyleSheetManager shouldForwardProp={isPropValid}>
            <GlobalStyles />
            <ApolloProvider client={apolloClient}>
              <LocaleProvider locale="en">
                <GoogleReCaptchaProvider
                  reCaptchaKey={process.env.NEXT_PUBLIC_API_KEY_CAPTCHA as string}
                  scriptProps={{
                    defer: true, // optional, default to false
                  }}
                >
                  <WhatsAppProvider>
                    <Component {...pageProps} />
                  </WhatsAppProvider>
                </GoogleReCaptchaProvider>
              </LocaleProvider>
            </ApolloProvider>
          </StyleSheetManager>
        </ThemeProvider>
      </RollbarProvider>
    </>
  );
}

export default MyApp;
