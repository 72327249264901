import { UploadFile } from '../types/schema';
import transformUrl from './transformURL';

export const formatSrcSet = (
  url: string | UploadFile,
  type?: string,
  options?: { resize?: { [key: string]: string }; quality?: number },
) => {
  const xlargeImage = typeof url === 'string' ? url : url?.formats?.xlarge?.url || url?.url;
  const largeImage = typeof url === 'string' ? url : url?.formats?.large?.url || url?.url;
  const mediumImage = typeof url === 'string' ? url : url?.formats?.medium?.url || url?.url;
  const smallImage = typeof url === 'string' ? url : url?.formats?.small?.url || url?.url;
  const originalImage = typeof url === 'string' ? url : url?.url;

  return `${transformUrl(smallImage, type, options)} 500w, 
          ${transformUrl(mediumImage, type, options)} 750w, 
          ${transformUrl(largeImage, type, options)} 1000w, 
          ${transformUrl(xlargeImage, type, options)} 1400w, 
          ${transformUrl(originalImage, type, options)} 2000w`;
};
