import React from 'react';
import { IoMdClose } from 'react-icons/io';
import Button from '../Button';
import { ContentWrapper, ModalWrapper } from './styles';

type ModalProps = {
  style?: React.CSSProperties;
  visible: boolean;
  onClose: () => void;
  onOk?: () => void;
  okText?: string;
};

const Modal: React.FC<React.PropsWithChildren<ModalProps>> = ({
  visible,
  style,
  onClose,
  children,
  onOk,
  okText,
}) => {
  return (
    <ModalWrapper onClick={onClose} className={visible ? 'show' : 'hide'}>
      <ContentWrapper style={style} onClick={(e) => e.stopPropagation()}>
        <Button className="close" onClick={onClose} aria-label="close">
          <IoMdClose style={{ marginTop: 3 }} size={18} />
        </Button>
        <div>{children}</div>
        {onOk && (
          <Button className="ok-button" style={{ padding: 8 }} onClick={onOk} aria-label="ok">
            {okText || 'Ok'}
          </Button>
        )}
      </ContentWrapper>{' '}
    </ModalWrapper>
  );
};

export default Modal;
