import { gql } from '@apollo/client';
import { HEADER_FOOTER_QUERY } from './menu';

export const CREATE_CONTACT = gql`
  mutation CreateContact(
    $email: String!
    $name: String!
    $message: String
    $phone: String
    $formTitle: String
    $url: String
  ) {
    createContactAndSendEmailWebsite(
      name: $name
      email: $email
      message: $message
      phone: $phone
      formTitle: $formTitle
      url: $url
    ) {
      name
    }
  }
`;

export const CONTACT_PAGE = gql`
  query ContactPage {
    ${HEADER_FOOTER_QUERY}
  }
`;
