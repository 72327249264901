export default {
  title:
    'App Masters - Custom software development, web and mobile experts, best choice in software Outsourcing in Brazil.',
  description:
    'A custom software development company expert in Node.js, React, working with web, mobile and cloud development.',
  openGraph: {
    type: 'website',
    locale: 'en',
    url: `${process.env.NEXT_PUBLIC_SITE_URL}en` || 'http://localhost:3000',
    site_name: 'App Masters',
    images: [
      {
        url: `${process.env.NEXT_PUBLIC_SITE_URL}appmasters-metaimage.png`,
        width: 800,
        height: 600,
        alt: 'App Masters Logo',
      },
    ],
  },
};
