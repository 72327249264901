export const hideChat = () => {
  const hs = document.getElementById('hubspot-messages-iframe-container');
  const wpp = document.getElementById('wpp-chat');

  // Hide hubspot chat
  if (hs) {
    const style = hs.getAttribute('style') || '';
    hs.setAttribute('style', `${style} display: none !important;`);
  }

  // Hide whatsapp chat
  if (wpp) {
    const style = wpp.getAttribute('style') || '';
    wpp.setAttribute('style', `${style} display: none !important;`);
  }
};

export const showChat = (showWpp: boolean) => {
  const hs = document.getElementById('hubspot-messages-iframe-container');
  const wpp = document.getElementById('wpp-chat');

  // Hide hubspot chat
  if (hs && !showWpp) {
    const style = hs.getAttribute('style') || '';
    hs.setAttribute('style', `${style.replace('display: none', 'display: initial')}`);
  }

  // Hide whatsapp chat
  if (wpp) {
    const style = wpp.getAttribute('style') || '';
    wpp.setAttribute('style', `${style.replace('display: none', 'display: flex')}`);
  }
};
