export const HUBSPOT_TRACKING_TOKEN = 'hubspotutk';

interface ContextType {
  pageUri: string;
  pageName: string;
}

export const submitHubSpotForm = async (
  formId: string,
  portalId: string,
  form: { [key: string]: string },
  context: ContextType,
  newsletter?: boolean,
) => {
  const fields = [];
  for (const attr in form) {
    fields.push({ name: attr, value: form[attr] });
  }
  const communicationIds: number[] | undefined = process.env
    .NEXT_PUBLIC_HUBSPOT_COMMUNICATION_SUBSCRIPTION_TYPE_IDS
    ? JSON.parse(process.env.NEXT_PUBLIC_HUBSPOT_COMMUNICATION_SUBSCRIPTION_TYPE_IDS)
    : undefined;

  const hubspotTrackingToken = document.cookie
    ?.split(`${HUBSPOT_TRACKING_TOKEN}=`)[1]
    ?.split(';')[0];

  return fetch(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`, {
    method: 'post',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      fields,
      context: { ...context, ...(hubspotTrackingToken && { hutk: hubspotTrackingToken }) },
      ...(!!communicationIds &&
        communicationIds.length > 0 && {
          legalConsentOptions: {
            consent: {
              consentToProcess: true,
              text: 'I agree to allow App Masters to store and process my personal data.',
              ...(newsletter && {
                communications: communicationIds.map((value) => ({
                  value: true,
                  subscriptionTypeId: Number(value),
                  text: 'I agree to receive marketing communications from App Masters.',
                })),
              }),
            },
          },
        }),
    }),
  });
};
