import styled from 'styled-components';

export const ModalWrapper = styled.div`
  position: fixed;
  background-color: rgb(60, 60, 60, 0.7);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 200px;
  transition: all 0.4s;

  &.show {
    z-index: 999;
    opacity: 1;
  }

  &.hide {
    opacity: 0;
    z-index: -1;
  }
`;

export const ContentWrapper = styled.div`
  background-color: white;
  border-radius: 10px;
  position: relative;
  max-width: 600px;
  margin: auto;
  display: flex;
  flex-direction: column;

  .close {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 25px !important;
    height: 25px !important;
    padding: 0 !important;
    outline: none;
    border: none;
    background-color: white;
    cursor: pointer;

    &:focus {
      border: none;
    }
  }

  button.ok-button {
    align-self: flex-end;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  h3 {
    width: 100%;
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
  }

  & > div {
    padding: 20px;
  }
`;
