/* eslint-disable @typescript-eslint/no-explicit-any */
import slugify from 'slugify';
import logging from './logging';

/**
 * translateProperty
 * @param data Component object to have property translated
 * @param property property to be translated
 * @param dynamicField Dynamic field name from data object
 * @returns
 */
export const translateProperty = (
  data: Record<string, unknown>,
  properties: string | string[],
  dynamicField: string,
) => {
  let translatedObj = data;
  for (const property of properties) {
    const { [dynamicField]: dynamic, ...service } = translatedObj;
    const obj = (
      dynamic as {
        [key: string]: { [key: string]: string };
      }[]
    )?.map((item) => {
      const name = `${property}${item.__typename}`;
      const value = item[name];
      if (value !== undefined) {
        const { [name]: _, ...obj } = item;
        return { [property]: value, ...obj };
      } else return item;
    });
    translatedObj = { [dynamicField]: obj, ...service };
  }

  return translatedObj;
};

export type Data = {
  id?: string;
  attributes?: Record<string, unknown>;
};

export type FlattenArg = { [key: string]: { data: Data[] | Data } };

/**
 * Remove first level os nesting from Strapi queries response
 * @param data object data to remove nesting
 * @returns new object
 */
export const flatten = (data: FlattenArg) => {
  return Object.keys(data).reduce((acc, key) => {
    return {
      ...acc,
      [key]: (data[key]?.data as Data[])?.length
        ? (data[key]?.data as Data[])?.map((entry) => {
            if (entry.attributes)
              return {
                ...(entry?.id && { id: entry.id }),
                ...entry.attributes,
              };
            return entry;
          })
        : {
            ...((data[key]?.data as Data)?.id && { id: (data[key]?.data as Data)?.id }),
            ...(data[key]?.data as Data)?.attributes,
          },
    };
  }, {}) as Record<string, unknown>;
};

export const deepFlatten = (data: Data[]) =>
  data.map((d) => ({ ...(d.id && { id: d.id }), ...d.attributes }));

export const isLookingForJob = (message?: string) => {
  if (!message) return false;

  const whiteList = [
    'vaga',
    'estagio',
    'oportunidade',
    'emprego',
    'curriculo',
    'cv',
    'seletivo',
    'estagiario',
    'estagiarios',
    'estagiar',
    'aprendiz',
    'trabalho',
    'trabalhar',
    'cursando',
    'junior',
    'seletivo',
    'trainee',
  ];

  const whiteList2 = [
    'oportunidade',
    'estagio',
    'emprego',
    'curriculo',
    'experiencia',
    'vaga',
    'estagiario',
    'estagiarios',
    'estagiar',
    'aprendiz',
    'faculdade',
    'trabalho',
    'trabalhar',
    'estudando',
    'busca',
    'começando',
    'cursando',
    'junior',
  ];

  // whiteList words can't appear
  if (
    whiteList.filter(
      (word) => slugify(message, { replacement: ' ', lower: true }).indexOf(word) > -1,
    )?.length > 0
  ) {
    return true;
  }

  // whiteList2 words can appear only once
  if (
    whiteList2.filter(
      (word) => slugify(message, { replacement: ' ', lower: true }).indexOf(word) > -1,
    )?.length > 1
  )
    return true;
  return false;
};

// All components that have the field richTextId should be added to this list
const componentsWhiteList = ['Gallery', 'SectionQuote'];

export const getRegex = (whiteList?: string[]) =>
  new RegExp(
    `((?:${
      whiteList ||
      componentsWhiteList.reduce(
        (acc, curr, i) => acc + `\\[${curr}${i === componentsWhiteList.length - 1 ? '' : '|'}`,
        '',
      )
    })[^\\]]+(?:\\]))`,
    'g',
  );

const replaceArray = (str: string) => {
  for (const component of componentsWhiteList) {
    str = str.replace(component, '');
  }

  return str;
};

const componentShouldBeFound = (id: string) => {
  if (componentsWhiteList.find((component) => id.includes(component))) {
    return true;
  }
  return false;
};

// \[Gallery|\[SectionQuote
export const orderDataByRichTextId = (dynamicZone: any[]) => {
  let newDz = [] as any[];
  for (const dz of dynamicZone) {
    if (dz.__typename === 'ComponentComponentsRichtext') {
      const regex = getRegex();

      const components = dz.body.split(regex);
      const mappedComponents = components.map((component: string) => {
        const foundComponent = dynamicZone.find(
          (dzAux) =>
            dzAux.richTextId &&
            replaceArray(component.replace('[', '').replace(']', '')).trim() === dzAux.richTextId,
        );
        if (foundComponent) return foundComponent;
        else {
          if (componentShouldBeFound(component)) {
            logging.error(`Component with richTextId ${component} does not exist`);
          }
          return {
            ...dz,
            body: component,
          };
        }
      });
      newDz = newDz.concat(mappedComponents);
      continue;
    }

    if (dz.richTextId) continue;
    newDz.push(dz);
  }

  return newDz;
};

export const LOOKING_FOR_JOB_KEY = 'lookingForJob';
