import styled, { css } from 'styled-components';
import media from '../../../styles/media';

const bg = '#FCF5EB';
const primary = '#00a884';
const accent = '#25d366';

const unstyledButton = css`
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
`;

export const Container = styled.aside`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  gap: 1rem;
  z-index: 2;
`;

export const IconButton = styled.button`
  ${unstyledButton}
  padding: 4px;
  transition: scale 0.2s ease-in-out;
  width: fit-content;

  &:hover {
    scale: 1.1;
  }

  &:active {
    scale: 0.9;
  }

  &:focus-visible {
    outline: 2px solid ${accent};
    outline-offset: 2px;
  }
`;

export const Chat = styled.div<{ open: boolean }>`
  height: auto;
  max-height: ${({ open }) => (open ? '100vh' : '0')};
  width: 350px;
  background-color: ${bg};
  border-radius: 16px;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;

  ${media('extraSmallest', true)} {
    width: calc(100% - 1rem);
    margin-left: 1rem;
  }

  .title {
    display: flex;
    gap: 1rem;
    padding: 1rem;
    background-color: ${primary};

    h1 {
      font-size: 1.25rem;
      font-weight: normal;
      color: white;
      margin: 0;
    }

    button {
      ${unstyledButton}
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 28px;
        width: 28px;
        color: white;
      }
    }
  }

  > :last-child {
    background-color: transparent !important;
  }

  .content-wrapper > .cards-display.show {
    margin: 0;

    & > div:first-of-type {
      box-shadow: none;
    }
  }

  form {
    height: 100%;
    padding: 1rem !important;
    div.form-row {
      flex-direction: column;

      > div.input-label > div.react-tel-input > div.flag-dropdown {
        border: 1px solid #0003;
      }

      .input-label {
        input,
        > div input.form-control {
          border: 1px solid #0003;
        }
      }
    }
    textarea {
      border: 1px solid #0003;
    }
  }
  button {
    width: 100%;
    background: ${accent};
    color: white;
    padding: 0.5rem;
  }
`;
