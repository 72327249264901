import flatten from 'flat';
import React, { useContext, useMemo, useState } from 'react';
import { IntlProvider } from 'react-intl';

import en from '../../locales/en.json';
import pt from '../../locales/pt.json';

export type LocaleEnum = 'en' | 'pt';

type Translation = {
  [key in LocaleEnum]: { [key: string]: string };
};

const locales: Translation = {
  en: flatten(en),
  pt: flatten(pt),
};

type LocaleContext = {
  /**
   * The current locale of the user
   */
  locale: LocaleEnum;
  /**
   * Updates the locale
   */
  setLocale: (locale: LocaleEnum) => void;
  hasProvider: boolean;
};

const initialState: LocaleContext = {
  locale: 'en',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setLocale: () => {},
  hasProvider: false,
};

const LocaleContext = React.createContext<LocaleContext>(initialState);

export type LocaleProviderProps = { locale: LocaleEnum };

/**
 * The Locale provider for its react context
 */
export const LocaleProvider: React.FC<React.PropsWithChildren<LocaleProviderProps>> = ({
  locale: propLocale,
  children,
}) => {
  // Only run this once at startup
  const finalLocale = useMemo(() => {
    // Prefer the locale in the router, if not available, get the locale given as prop, lastly, get the initial state locale, which is the default locale
    const locale = propLocale || initialState.locale || 'en';

    if (locale && Object.keys(locales).findIndex((x) => x === locale) === -1) {
      console.warn(
        `LocaleProvider: Missing locale data for locale. Using default locale: en as fallback.`,
      );
      return 'en';
    } else {
      return locale;
    }
  }, [propLocale]);

  const [locale, set] = useState<LocaleEnum>(finalLocale);

  const setLocale = (locale: LocaleEnum) => {
    set(locale);
  };

  const messages = useMemo(() => locales[locale], [locale]);

  return (
    <IntlProvider locale={locale} messages={messages}>
      <LocaleContext.Provider
        value={{
          hasProvider: true,
          locale,
          setLocale,
        }}
      >
        {children}
      </LocaleContext.Provider>
    </IntlProvider>
  );
};

export const useLocaleContext = () => useContext(LocaleContext);

/**
 * A hook that returns the current locale
 */
export const useLocale = () => {
  const { locale, setLocale } = useLocaleContext();
  return useMemo(() => [locale, setLocale], [locale, setLocale]);
};
