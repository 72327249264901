export const HEADER_FOOTER_QUERY = `
  header: menus(filters: { title: { eq: "Header"} }, locale: "all") {
    data {
      id
      attributes {
        title
        locale
        items {
          label
          url
        }
      }
    }
  }
  technologies: menus(filters: { title: { eq: "Main Technologies"} }, locale: "en") {
    data {
      id
      attributes {
        title
        locale
        items {
          label
          url
        }
      }
    }
  }
  footerPt: menus(locale: "pt") {
    data {
      id
      attributes {
        title
        locale
        items {
          label
          url
        }
      }
    }
  }
  footerEn: menus(locale: "en") {
    data {
      id
      attributes {
        title
        locale
        items {
          label
          url
        }
      }
    }
  }
  services: menus(filters: { title: {eq: "Services"} }, locale: "en") {
    data {
      id
      attributes {
        title
        locale
        items {
          label
          url
        }
      }
    }
  }
`;
