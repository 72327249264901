import ReactInputMask, { Props } from 'react-input-mask';
import styled from 'styled-components';
import { InputStyle } from './input';

const PhoneWithMask = styled(ReactInputMask).withConfig({
  shouldForwardProp: () => true,
})<Props & { formatChars: Record<string, string> }>`
  ${InputStyle};
`;

export default PhoneWithMask;
