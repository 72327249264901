import styled, { css } from 'styled-components';
import media from '../../../styles/media';

export const CardsDisplayWrapper = styled.div<{ columns: number }>`
  display: grid;
  gap: 32px;
  grid-template-columns: 1fr;
  ${media('medium')} {
    grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  }
`;

export const CardItem = styled.div<{
  $transparent?: boolean;
  $flat?: boolean;
  $rounded?: boolean;
  isOnlyTitle?: boolean;
}>`
  text-align: center;
  padding: 16px;
  position: relative;

  ${(props) =>
    !props.$rounded
      ? css`
          ${!props.$flat && 'box-shadow: 0px 3px 20px 0px rgba(216, 216, 216, 0.45);'}
          border-radius: 4px;
          background-color: ${props.$transparent ? 'transparent' : 'white'};
          transition: all 200ms ease-in 0s;
          &:hover {
            transform: translateY(-5px);
            box-shadow: 0px 3px 20px 0px rgba(216, 216, 216, 0.45);
          }
        `
      : css`
          > a {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            padding: 16px;
            margin-bottom: 16px;
            background-color: white;
            border-radius: 100%;
            width: 120px;
            height: 120px;
            transition: all 200ms ease-in 0s;
            cursor: pointer;
            :hover {
              transform: translateY(-5px);
              box-shadow: 0px 3px 20px 0px rgba(216, 216, 216, 1);
            }
          }
        `}

  .img {
    margin: auto;
    width: 70px;
    height: 70px;
    justify-self: center;
  }

  h4 {
    margin: 0;
    margin-bottom: 16px;
    margin-top: 8px;
  }

  p {
    text-align: center;
    max-width: 750px;
  }

  ${(props) =>
    props.isOnlyTitle &&
    css`
      display: flex;
      align-items: center;
      text-align: left;
      padding: 0;
      padding-bottom: 8px;
      gap: 15px;
      svg {
        margin-top: 8px;
        margin-left: 8px;
      }
    `}

  button.icon-button {
    background-color: transparent;
    padding: 0;
    border: none;
    position: absolute;
    top: 8px;
    transition: 0.2s;
    cursor: pointer;

    &:hover {
      filter: brightness(0.85);
    }

    &:focus {
      outline: none;
    }
  }

  button.lock {
    right: 8px;
  }

  button.delete {
    right: 34px;
  }

  .tooltip {
    padding: 4px 10px;
    opacity: 1 !important;
    width: max-content !important;
    z-index: 99 !important;
  }
`;
