import { initializeApp } from 'firebase/app';
import {
  getFirestore,
  collection,
  getDoc,
  onSnapshot,
  Unsubscribe,
  DocumentReference,
  doc as firebaseDoc,
  addDoc,
  setDoc,
  deleteDoc,
  Timestamp,
} from 'firebase/firestore';

// console.log(process.env.NEXT_PUBLIC_FIREBASE_CONFIG);

// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
const firebaseConfig = JSON.parse(process.env.NEXT_PUBLIC_FIREBASE_CONFIG || '{}');

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Getting database
const db = getFirestore(app);
type Path = [path: string, ...pathSegments: string[]];

const getCollection = (path: Path) => {
  return collection(db, ...path);
};

export const getDocument = (path: Path, id: string) => {
  return firebaseDoc(db, ...path, id);
};

export const getDocumentSnapshot = <T>(ref: DocumentReference<T>) => {
  return getDoc<T>(ref);
};

export const setDocumentListener = async <Data>(
  path: [path: string, ...extraPath: string[]],
  id: string,
  onValueChange: (data: Data) => void,
  unsub?: React.MutableRefObject<Unsubscribe | null>,
  setLoading?: (loading: boolean) => void,
) => {
  try {
    const docRef = firebaseDoc(db, ...path, id);
    // Setting listener (return is the unsubscribe function)
    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (!doc.exists()) onValueChange(null as unknown as Data);
      else {
        onValueChange({ ...doc.data(), id: doc.id } as unknown as Data);
      }
      setLoading?.(false);
    });
    if (unsub) (unsub.current = unsub.current), unsubscribe;
    return unsubscribe;
  } catch (error) {
    console.error('err', error);
  }
};

export const setListListener = async <Data>(
  path: [path: string, ...extraPath: string[]],
  onValueChange: (data: Data[]) => void,
  unsub?: React.MutableRefObject<Unsubscribe | null>,
  setLoading?: (loading: boolean) => void,
) => {
  try {
    const collection = getCollection(path);
    // Setting listener (return is the unsubscribe function)
    const unsubscribe = onSnapshot(collection, (doc) => {
      const data: Data[] = [];
      doc.forEach((item) => {
        if (item.exists()) {
          data.push({ ...item.data(), id: item.id } as unknown as Data);
        }
      });

      onValueChange(data);
      setLoading?.(false);
    });
    if (unsub) (unsub.current = unsub.current), unsubscribe;
    return unsubscribe;
  } catch (error) {
    console.error(error);
  }
};

export const setIndexDocumentListener = async <Data>(
  path: [path: string, ...extraPath: string[]],
  index: number,
  onValueChange: (data: Data) => void,
  unsub?: React.MutableRefObject<Unsubscribe | null>,
  setLoading?: (loading: boolean) => void,
) => {
  try {
    const collection = getCollection(path);
    // Setting listener (return is the unsubscribe function)
    const unsubscribe = onSnapshot(collection, (doc) => {
      const indexDoc = doc.docs[index];

      if (indexDoc?.exists()) {
        onValueChange({ ...indexDoc.data(), id: indexDoc.id } as unknown as Data);
      }

      setLoading?.(false);
    });
    if (unsub) (unsub.current = unsub.current), unsubscribe;
    return unsubscribe;
  } catch (error) {
    console.error(error);
  }
};

export const createOrUpdateItem = async <Data>(
  path: Path,
  data: Data,
  id?: string,
  merge?: boolean,
) => {
  try {
    const now = Timestamp.fromDate(new Date());

    const isAdmin = localStorage.getItem('allowed-admin') === 'true';

    if (!id) {
      const collection = getCollection(path);
      return await addDoc(collection, { ...data, ...(!isAdmin && { updatedAt: now }) });
    } else {
      const item = getDocument(path, id);
      return await setDoc(item, { ...data, ...(!isAdmin && { updatedAt: now }) }, { merge });
    }
  } catch (error) {
    // logging.error(error as Error);
    throw error;
  }
};

export const deleteItem = async (path: Path, id: string) => {
  try {
    const item = getDocument(path, id);
    await deleteDoc(item);
  } catch (error) {
    // logging.error(error as Error);
  }
};

export const createRef = (path: Path) => {
  try {
    return firebaseDoc(getCollection(path));
  } catch (error) {
    throw error;
  }
};

// console.log(app, db);
// Firebase App
export const firebase = app;
export { db };
