import color from 'color';

const lightAndDark = (colorHex: string, light?: number, dark?: number) => {
  const lightColor = color(colorHex)
    .lighten(light || 1)
    .desaturate(0.8)
    .hex();

  const darkColor = color(colorHex)
    .darken(dark || 0.2)
    .hex();

  return [colorHex, lightColor, darkColor];
};
// Default sasu colors extracted from theme
// export const [white, lightWhite, darkWhite] = lightAndDark('#fff');
export const [gray, lightGray, darkGray] = lightAndDark('#f2f2f2');

export const [authorText, lightAuthorText, darkAuthorText] = lightAndDark('#556172');

export const [sectionBg, lightSectionBg, darkSectionBg] = lightAndDark('#f7f7f7');

export const [paragraph, lightParagraph, darkParagraph] = lightAndDark('#4F4F4F');

export const [border, lightBorder, darkBorder] = lightAndDark('#edeff2');
export const [cardBorder, lightCardBorder, darkCardBorder] = lightAndDark('#D9DFEF');
export const [footerBg, lightFooterBg, darkFooterBg] = lightAndDark('#303030');
export const [sidebarBt, lightSidebarBg, darkSidebarBg] = lightAndDark('#fbf9ff');

// Additional colors

export const [grey, lightGrey, darkGrey] = lightAndDark('#1F2F59');

export const lighterGrey = color(grey).lighten(0.7).hex();
export const lightestGrey = color(grey).lighten(1.2).hex();

export const [red, lightRed, darkRed] = lightAndDark('#a8290e');

export const [green, lightGreen, darkGreen] = lightAndDark('#48b43a');

export const lighterGreen = color(green).lighten(0.8).hex();

export const tertiaryBlue = '#e3ecf2';
export const background = '#fafafa';
export const tertiaryBlueText = '#175974';
export const [primary, lightPrimary, darkPrimary] = lightAndDark('#1D42CF');

// Design 2023

export const [blue, lightBlue] = lightAndDark('#1D42CF');
export const darkBlue = '#1F2F59';
export const darkGradient = 'linear-gradient(78.66deg, #1D42CF -22.45%, #3888FC 105.12%)';
export const lightGrayBlue = '#E1EDFF';

export const lightGradient =
  'radial-gradient(141.77% 218.28% at -42.79% 9.15%, #dcebff 0%, #ffffff 100%)';
export const white = '#fafafa';

export const darkText = '#1F2F59';
export const lightText = '#C0DAFF';
export const darkTextPale = '#566384';
export const mutedText = '#a0a6ca';

export const newBorder = '#bcc3d7';
export const cardBlue = '#AECFFF';
export const lightCardBlue = `${cardBlue}66`;
export const boldText = '#1E41A0';
