import styled, { css } from 'styled-components';
import media from '../../../styles/media';

export const ContactFormWrapper = styled.div<{
  background: 'white' | 'primary';
  success?: boolean;
  inputError?: boolean;
}>`
  background-color: ${(props) => props.theme.colors[props.background]};
  margin: auto;

  h1 {
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    margin-top: 0px;
    margin-bottom: 20px;
    color: ${(props) => (props.background === 'primary' ? 'white' : props.theme.colors.darkText)};
  }

  p {
    text-align: center;
    margin-top: 0;
    font-size: 20px;
    margin-bottom: 24px;
  }

  .content-wrapper {
    position: relative;

    ${(props) =>
      props.success &&
      css`
        height: 340px;

        @media screen and (max-width: 368px) {
          height: 410px;
        }
      `}

    /* Card display styles */
    & > div:first-of-type {
      margin-top: 50px;
      transform: translateX(0%);
      position: absolute;
      left: -100%;
      width: 100%;
      max-width: 1200px;
      opacity: 0;
      padding: 0 26px;
      transition: all 1s;

      &.show {
        transform: translateX(-50%);
        left: 50%;
        opacity: 1;
      }

      & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        margin: auto;
        padding: 24px;

        svg {
          color: ${(props) => props.theme.colors.green};
        }

        svg + div {
          margin-top: 0 !important;

          h2 {
            font-size: 34px !important;
            margin: 0;
            font-weight: 300 !important;
          }
        }

        p {
          font-size: 18px;
          margin: 0;
        }
      }
    }

    form {
      padding: 0 26px;
      margin: auto;
      display: flex;
      flex-direction: column;
      gap: 16px;
      transform: translateX(0%);
      max-width: 1200px;
      width: 100%;
      transition: all 1s;
      opacity: 1;

      ${(props) =>
        props.inputError &&
        css`
          ${media('medium', true)} {
            .required-message {
              text-align: right !important;
            }
          }
        `}

      &.hide {
        /* position: absolute; */
        /* left: 100%; */
        transform: translateX(100%);
        opacity: 0;
      }

      div.form-row {
        display: flex;
        gap: 16px;
        width: 100%;

        div {
          flex: 1;
        }

        ${media('mediumPlus', true)} {
          flex-direction: column;
        }
      }

      div.input-label {
        position: relative;

        .error-message {
          position: absolute;
          text-align: left;
          font-size: 14px;
          top: 44px;
          color: ${(props) => props.theme.colors.darkRed} !important;
          font-size: bold;
        }
      }

      .required-message {
        margin: 0;
        text-align: left;
        font-size: 14px;
        margin-top: -14px;
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;

        svg {
          margin-top: 0px;
        }
      }

      footer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        ${media('extraSmallest', true)} {
          flex-direction: column;
          gap: 12px;
          justify-content: flex-start;
          align-items: flex-start;

          button {
            margin: 0;
          }
        }
      }
      .checkbox-wrapper {
        display: inline;
        a {
          font-weight: bold;
          text-decoration: underline;
        }

        label {
          padding-left: 24px;
          padding-right: 8px;
        }
      }

      label.checkbox {
        display: inline-block;
        position: relative;
        font-size: 0.9rem;

        cursor: pointer;
        color: ${(props) =>
          props.background === 'white' ? props.theme.colors.darkText : props.theme.colors.white};

        .checkmark {
          position: absolute;
          top: 2px;
          left: 0;
          bottom: 0;
          height: 0.9rem;
          width: 0.9rem;
          border: 2px solid
            ${(props) =>
              props.background === 'white'
                ? props.theme.colors.darkText
                : props.theme.colors.white};
          border-radius: 4px;
          &::after {
            content: '';
            position: absolute;
            display: none;
            top: -2px;
            left: 2px;
            width: 7px;
            height: 12px;
            border: solid
              ${(props) =>
                props.background === 'white' ? props.theme.colors.white : props.theme.colors.blue};
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }

        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
          &:checked ~ .checkmark {
            background-color: ${(props) =>
              props.background === 'white'
                ? props.theme.colors.darkText
                : props.theme.colors.white};
            &::after {
              display: block;
            }
          }
        }
      }

      .shake-animation {
        animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        animation-iteration-count: infinite;
      }
    }
  }

  ul.country-list {
    span.country-name {
      color: ${(props) => props.theme.colors.darkText} !important;
    }
    span.dial-code {
      color: ${(props) => props.theme.colors.darkTextPale} !important;
    }
  }
`;

export const FormErrorMessage = styled.span`
  color: ${(props) => props.theme.colors.red} !important;
`;

export const SuccessMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: auto;

  svg {
    color: ${(props) => props.theme.colors.green};
  }

  h3 {
    font-size: 34px;
    margin: 0;
  }

  p {
    font-size: 20px;
  }
`;

export const LookingForJobText = styled.div`
  max-width: 800px;
  width: 100%;
  margin: auto;

  p {
    text-align: left !important;
    a {
      text-decoration: underline;
    }
  }
`;
