import * as colors from './colors';

const theme = {
  name: 'Default',
  colors,
  border: {
    borderRadius: '10px',
    borderDark: '1px solid' + colors.newBorder,
  },
  // TODO: Add spacings?
};

export default theme;

export type AppTheme = typeof theme;
