import axios from 'axios';
import { RecaptchaResponse } from '../pages/api/recaptcha';
import logging from './logging';

export const verifyRecaptchaToken = async (token: string): Promise<RecaptchaResponse> => {
  try {
    const response = await axios.post<RecaptchaResponse>('/api/recaptcha', { token });
    return response.data;
  } catch (error) {
    logging.error('Failed to validate the recaptcha token', { token, error });
    return {
      success: false,
      'error-codes': [],
    };
  }
};

export const checkIsHuman = (response: RecaptchaResponse) => {
  const thresholdScore = 0.5; // 1 = human | 0 = robot
  return response.success === true && response.score >= thresholdScore;
};
