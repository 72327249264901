import styled from 'styled-components';
import { AppTheme } from '../../../styles/theme';
import media from '../../../styles/media';

export type TypographyProps = {
  variant: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'div';
  children: React.ReactNode;
  fontWeight?: string;
  fontSize?: string;
  marginBottom?: string;
  marginLeft?: string;
  color?: keyof AppTheme['colors'];
  style?: React.CSSProperties;
};

const StyledTypo = styled.div<Omit<TypographyProps, 'variant'>>`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  span,
  p {
    font-weight: ${({ fontWeight }) => fontWeight ?? 500} !important;
    font-size: ${({ fontSize }) => fontSize} !important;
    margin-bottom: ${({ marginBottom }) => marginBottom} !important;
    color: ${({ color, theme }) => theme.colors[color || 'darkText'] ?? 'black'};
    margin-left: ${({ marginLeft }) => marginLeft} !important;
  }
  span {
    font-size: inherit !important;
    padding-left: 0 !important;
  }
  @media screen and (max-width: 576px) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: ${({ fontSize }) => `calc(${fontSize} * 0.75)`} !important;
    }
  }
`;

const TypographyH1 = styled.h1`
  font-size: 48px;
  line-height: 1.1;
  font-weight: 500;

  ${media('medium', true)} {
    font-size: 40px !important;
  }

  ${media('extraSmallest', true)} {
    font-size: 36px !important;
  }
`;

const TypographyH2 = styled.h2`
  font-size: 36px;
  line-height: 1.2em;
`;

const TypographyH3 = styled.h3`
  font-size: 28px;
`;

const TypographyH4 = styled.h4`
  font-size: 22px;
  line-height: 1.1;
`;

const TypographyH5 = styled.h5``;

const TypographyH6 = styled.h6``;

const TypographyParagraph = styled.p`
  line-height: 2;
  font-size: 18px;
  font-weight: 400;
`;

const TypographyDiv = styled.div`
  line-height: 2;
  font-size: 18px;
  font-weight: 400;
`;

const checkChildrenLineBreak = (children: React.ReactNode) => {
  if (typeof children === 'string') {
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: children.replace(/\n/g, '<br/>'),
        }}
      />
    );
  }
  return children;
};

const Typography: React.FC<TypographyProps> = ({ variant, children, ...props }) => {
  const _children = checkChildrenLineBreak(children);
  const variantObject = {
    h1: <TypographyH1>{_children}</TypographyH1>,
    h2: <TypographyH2>{_children}</TypographyH2>,
    h3: <TypographyH3>{_children}</TypographyH3>,
    h4: <TypographyH4>{_children}</TypographyH4>,
    h5: <TypographyH5>{_children}</TypographyH5>,
    h6: <TypographyH6>{_children}</TypographyH6>,
    p: <TypographyParagraph>{_children}</TypographyParagraph>,
    div: <TypographyDiv>{_children}</TypographyDiv>,
  };

  return <StyledTypo {...props}>{variantObject[variant]}</StyledTypo>;
};

export default Typography;
