import { FaSpinner } from 'react-icons/fa';
import styled from 'styled-components';

export const LoadingIcon = styled(FaSpinner)`
  margin-top: 5px;
  font-size: 21px;
  -webkit-animation: icon-spin 1s infinite linear;
  animation: icon-spin 1s infinite linear;
  @-webkit-keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
  @keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
`;
