import styled, { css } from 'styled-components';
import media from '../../../styles/media';
import { AppTheme } from '../../../styles/theme';

export type ButtonVariant = 'default' | 'secondary' | 'tertiary' | 'primary';

type ButtonProps = {
  variant?: ButtonVariant;
  fullWidth?: boolean;
  backgroundColor?: string;
} & { theme: AppTheme };

const Button = styled.button<ButtonProps>`
  position: relative;
  padding: 15px 20px;
  gap: 10px;
  border: 0;
  font-weight: 400;
  font-size: 1em;
  ${media('medium')} {
    padding: 15px 20px;
  }

  transition:
    border-color 0.5s,
    background-color 0.5s,
    color;
  transform-origin: bottom;
  border-radius: 8px;
  cursor: pointer;

  ${(props) =>
    props.backgroundColor === 'lightGradient' ||
    props.backgroundColor === 'white' ||
    props.variant === 'primary'
      ? css`
          border: 0;
          color: ${props.theme.colors.white};
          background: linear-gradient(78.66deg, #1d42cf -22.45%, #3888fc 105.12%);
        `
      : css`
          border: 0;
          color: #1d42cf;
          background: radial-gradient(141.77% 218.28% at -42.79% 9.15%, #dcebff 0%, #ffffff 100%);
        `};

  z-index: 1;

  &:hover {
    filter: saturate(1.3);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export default Button;
