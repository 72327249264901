import React, { PropsWithChildren } from 'react';
import { useRouter } from 'next/router';
import WhatsAppChat from '../components/elements/WhatsAppChat';
import { useLocalStorage } from 'react-use';

type WhatsAppContextProps = {
  setShowWhatsAppButton: (bool: boolean) => void;
};

const WhatsAppContext = React.createContext({} as WhatsAppContextProps);

const WhatsAppProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [showWhatsAppButton, setShowWhatsAppButton] = useLocalStorage('SHOW_WPP_BUTTON', true);

  const router = useRouter();
  const showWppChat = router.locale === 'pt' && showWhatsAppButton;

  return (
    <WhatsAppContext.Provider value={{ setShowWhatsAppButton }}>
      {children}
      {showWppChat && <WhatsAppChat />}
    </WhatsAppContext.Provider>
  );
};

export const useWhatsApp = () => React.useContext(WhatsAppContext);

export default WhatsAppProvider;
