type Status = 'valid' | 'invalid' | 'catch-all' | 'unknown' | 'spamtrap' | 'abuse' | 'do_not_mail';
type SubStatus =
  | 'antispam_system'
  | 'greylisted'
  | 'mail_server_temporary_error'
  | 'forcible_disconnect'
  | 'mail_server_did_not_respond'
  | 'timeout_exceeded'
  | 'failed_smtp_connection'
  | 'mailbox_quota_exceeded'
  | 'exception_occurred'
  | 'possible_trap'
  | 'role_based'
  | 'global_suppression'
  | 'mailbox_not_found'
  | 'no_dns_entries'
  | 'failed_syntax_check'
  | 'possible_typo'
  | 'unroutable_ip_address'
  | 'leading_period_removed'
  | 'does_not_accept_mail'
  | 'alias_address'
  | 'role_based_catch_all'
  | 'disposable'
  | 'toxic';

type Gender = 'male' | 'female' | 'other' | null;

type SuccessResponse = {
  address: string;
  status: Status;
  sub_status: SubStatus;
  account: string;
  domain: string;
  did_you_mean?: string | null;
  domain_age_days: number | null;
  free_email: boolean;
  mx_found: boolean;
  mx_record: string | null;
  smtp_provider: string | null; // You may want to update this based on actual data types
  firstname: string | null;
  lastname: string | null;
  gender: Gender;
  city: string | null;
  region: string | null;
  zipcode: string | null;
  country: string | null;
  processed_at: string; // Assuming a string representation of UTC time
};

type ErrorResponse = {
  error: string;
};

type ZeroBounceResponse = SuccessResponse | ErrorResponse;

function isErrorResponse(response: ZeroBounceResponse): response is ErrorResponse {
  return (response as ErrorResponse).error !== undefined;
}

function isSuccessResponse(response: ZeroBounceResponse): response is SuccessResponse {
  return !isErrorResponse(response);
}

export async function checkValidEmail(email: string): Promise<{
  validEmail: boolean;
  response: ZeroBounceResponse | null;
}> {
  try {
    const res = await fetch(
      `https://api.zerobounce.net/v2/validate?api_key=${process.env.NEXT_PUBLIC_ZERO_BOUNCE_API_KEY}&email=${email}&ip_address=`,
    );

    const data = (await res.json()) as ZeroBounceResponse;

    if (isSuccessResponse(data)) {
      const status = data.status;
      const substatus = data.sub_status;
      const isInvalid =
        status === 'invalid' ||
        status === 'abuse' ||
        (status === 'do_not_mail' &&
          ['disposable', 'role_based_catch_all', 'global_suppression', 'toxic'].includes(
            substatus,
          ));
      return {
        validEmail: !isInvalid,
        response: data,
      };
    } else {
      return { validEmail: false, response: data };
    }
  } catch (error) {
    return { validEmail: false, response: error as ErrorResponse };
  }
}
