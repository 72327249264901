import React, { PropsWithChildren } from 'react';
import { Provider, ErrorBoundary } from '@rollbar/react';
import type Rollbar from 'rollbar';
import ErrorView from '../views/ErrorView/ErrorView';
import { getRollbarConfig } from '../utils/logging';

const RollbarProvider: React.FC<PropsWithChildren<{ instance?: Rollbar }>> = ({
  children,
  instance,
}) => {
  return (
    <Provider {...(instance ? { instance } : { config: getRollbarConfig() })} instance={instance}>
      <ErrorBoundary fallbackUI={ErrorView}>{children}</ErrorBoundary>
    </Provider>
  );
};

export default RollbarProvider;
