import Link from 'next/link';
import { formatSrcSet } from '../../../utils/formatURLs';
import Typography from '../Typography';
import { CardsDisplayWrapper, CardItem } from './styles';
import { FiCheckSquare } from 'react-icons/fi';
import { useMemo, useState } from 'react';
import { AiOutlineLock, AiOutlineUnlock } from 'react-icons/ai';
import { useTheme } from 'styled-components';
import { Tooltip } from 'react-tooltip';
import { createOrUpdateItem, deleteItem } from '../../../utils/firebase';
import { BiTrash } from 'react-icons/bi';
import Modal from '../Modal';

export type Card = {
  id: string;
  image?: string | React.ReactNode;
  title: string;
  slug?: string;
  description?: string | React.ReactNode;
  locked?: boolean;
  navigationIcon?: React.ReactNode;
  language?: 'pt' | 'en';
};

export type CardsDisplayProps = {
  columns?: number;
  ghost?: boolean;
  flat?: boolean;
  rounded?: boolean;
  cards: Card[];
  pathLink?: string;
  background?: string;
  isBudget?: boolean;
  imageIsComponent?: boolean;
  className?: string;
};

const CardsDisplay: React.FC<CardsDisplayProps> = ({
  cards,
  ghost,
  flat,
  rounded,
  columns = 2,
  pathLink,
  isBudget,
  imageIsComponent,
  className,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [cardId, setCardId] = useState<string | null>(null);

  const theme = useTheme();

  const isOnlyTitle = useMemo(() => {
    let onlyTitle = true;

    cards.forEach((card) => {
      if (card.description || card.image) {
        onlyTitle = false;
        return;
      }
    });
    return onlyTitle;
  }, [cards]);

  const LinkCard: React.FC<React.PropsWithChildren<{ pathLink?: string; card: Card }>> = ({
    card,
    pathLink,
    children,
  }) => {
    if (!card.image && pathLink) {
      return (
        <Link
          href={`/${card.language}${pathLink}${card.slug || card.id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {children}
        </Link>
      );
    } else {
      return <>{children}</>;
    }
  };

  const handleLock = (id: string, operation: 'lock' | 'unlock') => {
    createOrUpdateItem(['budgets'], { locked: operation === 'lock' }, id, true);
  };

  const deleteBudget = (id: string) => {
    deleteItem(['budgets'], id);
  };
  return (
    <CardsDisplayWrapper columns={columns} className={`cards-display ${className}`}>
      {cards.map((card, i) => (
        <CardItem
          $transparent={ghost}
          $rounded={rounded}
          $flat={flat}
          isOnlyTitle={isOnlyTitle}
          key={`card-display-item-${card.id}-${i}`}
        >
          {isBudget && (
            <>
              <Tooltip
                id={`${card.title}_${i}`}
                className={`tooltip tooltip-${card.locked ? 'locked' : 'unlocked'}`}
                arrowColor="transparent"
              />
              {card.locked ? (
                <button
                  className="icon-button lock"
                  data-tooltip-id={`${card.title}_${i}`}
                  data-tooltip-content="Destravar budget"
                  onClick={(e) => {
                    e.preventDefault();
                    handleLock(card.id, 'unlock');
                  }}
                >
                  <AiOutlineLock size={22} color={theme.colors.red} />
                </button>
              ) : (
                <button
                  className="icon-button lock"
                  data-tooltip-id={`${card.title}_${i}`}
                  data-tooltip-content="Travar budget"
                  onClick={(e) => {
                    e.preventDefault();
                    handleLock(card.id, 'lock');
                  }}
                >
                  <AiOutlineUnlock size={22} color={theme.colors.green} />
                </button>
              )}
              <Tooltip
                id={`${card.title}_${i}_delete`}
                className="tooltip"
                arrowColor="transparent"
              />
              <button
                className="icon-button delete"
                data-tooltip-id={`${card.title}_${i}_delete`}
                data-tooltip-content="Exluir budget"
                onClick={(e) => {
                  e.preventDefault();
                  setCardId(card.id);
                  setShowDeleteModal(true);
                }}
              >
                <BiTrash size={22} color={theme.colors.red} />
              </button>
            </>
          )}

          {!isOnlyTitle && pathLink && card.image && (
            <Link href={`${pathLink}/${card.slug}`}>
              {imageIsComponent ? (
                card.image
              ) : (
                <img
                  className="img"
                  srcSet={formatSrcSet(card.image as string, 'technology')}
                  alt="Imagem da tecnologia"
                  loading="lazy"
                />
              )}
            </Link>
          )}
          {!isOnlyTitle &&
            !pathLink &&
            card.image &&
            (imageIsComponent ? (
              card.image
            ) : (
              <img
                className="img"
                srcSet={formatSrcSet(card.image as string, 'technology')}
                alt=""
                loading="lazy"
              />
            ))}
          {isOnlyTitle && <FiCheckSquare fontSize={20} />}
          {!!card.title && (
            <LinkCard card={card} pathLink={pathLink}>
              <Typography
                style={!pathLink && card.image ? { marginTop: '10px' } : {}}
                variant="h2"
                fontSize="18px"
                marginBottom={isOnlyTitle ? '0' : '16'}
              >
                {card.title}
                {card.navigationIcon}
              </Typography>
            </LinkCard>
          )}
          {!isOnlyTitle &&
            !rounded &&
            (typeof card.description === 'string' ? (
              <Typography variant="p" color="darkText">
                {card.description}
              </Typography>
            ) : (
              card.description
            ))}
        </CardItem>
      ))}
      <Modal
        visible={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onOk={() => {
          deleteBudget(cardId as string);
          setShowDeleteModal(false);
        }}
        okText="Excluir"
      >
        <p style={{ marginBottom: 0, paddingTop: 10 }}>
          Essa operação não pode ser revertida. Tem certeza que deseja excluir o Budget?
        </p>
      </Modal>
    </CardsDisplayWrapper>
  );
};

export default CardsDisplay;
