import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import logging from './logging';

const httpLink = new HttpLink({
  uri: process.env.NEXT_PUBLIC_STRAPI_URL,
});

const errorLink = onError(({ graphQLErrors, forward, operation, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach((error) => {
      logging.error('Error caught by ErrorLink', error);
    });
  if (networkError) {
    logging.error('Error caught by ErrorLink - networkError', { error: networkError });
  }

  return forward(operation);
});

const authLink = new ApolloLink((operation, forward) => {
  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      Authorization: `Bearer ${process.env.NEXT_PUBLIC_API_AUTH_TOKEN || ''}`,
    },
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});

function createApolloClient() {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: from([authLink, errorLink, httpLink]),
    cache: new InMemoryCache(),
  });
}

export default createApolloClient;
