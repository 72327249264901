/* eslint-disable camelcase */
export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS;

type GTagEvent = {
  action: string;
  category?: string;
  label?: string;
  value?: number;
  conversionEventId?: string;
  searchTerm?: string;
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = (event: GTagEvent): void => {
  // Logging on dev to help debugging
  if (process.env.NODE_ENV === 'development') {
    // Cleaning undefined from the object to help visualization
    Object.keys(event).forEach(
      (key) => event[key as keyof GTagEvent] === undefined && delete event[key as keyof GTagEvent],
    );
    console.log(`Trying to send ${event.action} action to gtag`, event);
  }

  // Ignore if SSR or gtag not defined
  if (!process.browser || !GA_TRACKING_ID || !window.gtag) return;

  // Mounting event object
  const params: { [key: string]: string | number | undefined } = {};
  switch (event.action) {
    case 'conversion': {
      params['send_to'] = event.conversionEventId;
    }
    case 'search': {
      params['search_theme'] = event.searchTerm;
    }
    default: {
      params['event_category'] = event.searchTerm;
      params['event_label'] = event.label;
      params['value'] = event.value;
    }
  }

  // Sending to gtag
  window.gtag('event', event.action, params);
};
