import * as S from './styles';
import React from 'react';
import Image from 'next/image';
import { FiX } from 'react-icons/fi';
import ContactForm from '../../ui/ContactForm';

const hubspotFormId = process.env.NEXT_PUBLIC_HUBSPOT_WPP_FORM_ID;
const hubspotPortalId = process.env.NEXT_PUBLIC_HUBSPOT_WPP_PORTAL_ID;

export default function WhatsAppChat() {
  const [chatOpen, setChatOpen] = React.useState(false);

  function toggleChat() {
    setChatOpen((s) => !s);
  }

  return (
    <S.Container id="wpp-chat">
      <S.IconButton aria-label="Fale conosco pelo whatsapp" onClick={toggleChat}>
        <Image src="/img/whatsapp.png" alt="" height={50} width={50} />
      </S.IconButton>

      <S.Chat open={chatOpen}>
        <div className="title">
          <h1>Olá! Preencha os campos abaixo para falar agora com um de nossos especialistas:</h1>
          <button onClick={toggleChat} aria-label="Whatsapp">
            <FiX />
          </button>
        </div>
        <ContactForm
          hubspotFormId={hubspotFormId}
          hubspotPortalId={hubspotPortalId}
          hideMessage
          showTermsCheckBox
          urlOnNewTab
          destinationUrl="https://wa.me/5532999883755"
          background="white"
          buttonText="Iniciar conversa"
        />
      </S.Chat>
    </S.Container>
  );
}
