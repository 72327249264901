/* eslint-disable camelcase */
// IMPORTS
// -----------------------------------------
import Rollbar, { Configuration, LogArgument } from 'rollbar';

type User = {
  name: string;
  email: string;
};

/**
 * Get the available token
 */
export const getRollbarToken = () => {
  const clientToken = process.env.NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN; // Always available
  const serverToken = process.env.NEXT_PUBLIC_ROLLBAR_SERVER_TOKEN; // Available on server

  if ((!clientToken || !serverToken) && process.env.NEXT_PUBLIC_NODE_ENV === 'production') {
    const message =
      'Neither client nor server Rollbar tokens defined. Add them to your environment variables.';
    console.error(message, { clientToken, serverToken });
    throw new Error(message);
  }

  if (typeof window === 'undefined') {
    // Server side
    return serverToken;
  }

  // Return the available
  return clientToken;
};

/**
 * Mount Rollbar environment string
 */
const getRollbarEnvironment = () => {
  // Local or Online?

  let deployment = '';
  const project = typeof window !== 'undefined' ? 'website-client-side' : 'website-server-side';

  if (project === 'website-client-side')
    deployment = window.location.origin.indexOf('localhost') < 0 ? 'online' : 'local';
  else deployment = process.env.DB_HOST === 'localhost' ? 'local' : 'online';
  return `${project}.${process.env.NEXT_PUBLIC_ROLLBAR_ENV || process.env.NODE_ENV}.${deployment}`;
};

/**
 * Convert user to person format and save it on the local storage
 * @param user API user
 */
export const saveUserAfterFormSubmit = (user: User | null) => {
  // Cleaning storage if not defined (logout)
  if (!user) return localStorage.setItem('rollbar-person', '');

  // Creating Rollbar person and adding it to localStorage
  const person = JSON.stringify({
    username: user.name,
    email: user.email,
  });
  return localStorage.setItem('rollbar-person', person);
};

export const getRollbarConfig = () => {
  const token = getRollbarToken();
  const isClient = typeof window !== 'undefined';

  return {
    environment: getRollbarEnvironment(),
    accessToken: token,
    captureUncaught: true,
    captureUnhandledRejections: true,
    addErrorContext: true,
    onSendCallback: (_, args) => {
      if (token) {
        console.log(`Sending to Rollbar from ${isClient ? 'client' : 'server'} side`, args);
      } else {
        console.log(
          `Error should be sent to Rollbar from ${isClient ? 'client' : 'server'} side`,
          args,
        );
      }
    },
    payload: {
      client: {
        javascript: {
          source_map_enabled: true,
          code_version: process.env.NEXT_BUILD_ID,
          guess_uncaught_frames: true,
        },
      },
    },
  } as Configuration;
};

/**
 * Create and configure a new Rollbar instance
 */
export const getRollbar = () => {
  const config = getRollbarConfig();

  if (typeof window !== 'undefined') {
    // It's on client, maybe the person is available
    const person = localStorage.getItem('rollbar-person');
    if (person) {
      config.payload = { person: JSON.parse(person) };
    }
  }

  return new Rollbar(config);
};

type LoggingTypes = 'log' | 'error' | 'info' | 'debug' | 'critical' | 'warn' | 'warning';

/**
 * Generic Rollbar handler
 * @param type enum of possible rollbar loggings
 * @param args other arguments sent do rollbar
 */
const handleRollbarLog =
  (type: LoggingTypes) =>
  async (...args: LogArgument[]) => {
    // Logging error on console;
    if (process.env.NODE_ENV !== 'production') {
      const message = `Error will be send to Rollbar as ${type}`;
      if (type === 'critical' || type === 'error') console.error(message, ...args);
      else if (type === 'warn' || type === 'warning') console.warn(message, ...args);
      else console.log(message, ...args);
    }

    if (getRollbarToken()) {
      // Logging to Rollbar only if the token is defined
      const rollbar = await getRollbar();
      try {
        return rollbar[type](...args);
      } catch (error) {
        if (process.env.NODE_ENV !== 'development') {
          console.error(
            `Rollbar is failing to send this ${type}. This need to be fixed now!`,
            ...args,
          );
        }
      }
    } else {
      return console.log(`This error would be sent to rollbar as a "${type}" on production`);
    }
  };

// Creating logging object
const logging = {
  log: handleRollbarLog('log'),
  error: handleRollbarLog('error'),
  info: handleRollbarLog('info'),
  debug: handleRollbarLog('debug'),
  critical: handleRollbarLog('critical'),
  warn: handleRollbarLog('warn'),
  warning: handleRollbarLog('warning'),
};

export default logging;
