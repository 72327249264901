import { buildUrl } from 'cloudinary-build-url';

type transformOptionsType = {
  [type: string]: {
    quality?: number;
    resize?: {
      width: string;
      height: string;
      type: string;
    };
  };
};

const transformOptions: transformOptionsType = {
  projectBanner: {
    quality: 70,
  },
  projectThumbnail: {
    quality: 70,
  },
  postBanner: {
    quality: 70,
  },
  postThumbnail: {
    quality: 70,
  },
  postAuthor: {
    quality: 100,
    resize: {
      width: '65',
      height: '65',
      type: 'scale',
    },
  },
  technology: {
    resize: {
      type: 'fit',
      width: '70',
      height: '70',
    },
    quality: 70,
  },
  banner: {
    quality: 20,
  },
  bannerTertiary: {
    resize: {
      type: 'fit',
      width: '488',
      height: '460',
    },
    quality: 70,
  },
  notFound: {
    resize: {
      type: 'scale',
      width: '1000',
      height: '1000',
    },
    quality: 70,
  },
  userTestimony: {
    resize: {
      type: 'scale',
      width: '100',
      height: '100',
    },
    quality: 70,
  },
  whereToFind: {
    resize: {
      type: 'scale',
      width: '180',
      height: '55',
    },
    quality: 70,
  },
  gallery: {
    quality: 60,
  },
};

export default function transformUrl(
  url: string,
  type?: string,
  options?: { resize?: { [key: string]: string }; quality?: number },
) {
  return url
    ? buildUrl(url, {
        cloud: {
          cloudName: process.env.NEXT_PUBLIC_CLOUDINARY_NAME,
        },
        transformations: options || (type ? transformOptions[type] : undefined),
      })
    : null;
}
