import { FormattedMessage } from 'react-intl';
import { NotFoundWrapper } from './ErrorView.styles';
import Typography from '../../components/ui/Typography';
import { VscError } from 'react-icons/vsc';
import Button from '../../components/ui/Button';
import Link from 'next/link';
import { useEffect } from 'react';
import logging from '../../utils/logging';

type Props = {
  statusCode?: number;
  error?: Error | null;
};

const ErrorView: React.FC<Props> = ({ error, statusCode }) => {
  useEffect(() => {
    if (statusCode !== 404)
      logging.error(`[${statusCode}] User on the error page`, {
        error: JSON.stringify(error),
      });
  }, []);

  return (
    <div style={{ width: '100%' }}>
      <NotFoundWrapper>
        <img src={'/new-breadcrumb.png'} alt="Blue gradient" />
        <div>
          <div>
            <VscError size={100} />
          </div>
          <Typography variant="h2" color="white" fontWeight="600">
            <FormattedMessage id="error.message" />
          </Typography>
          <Link href="/" passHref>
            <a>
              <Button>
                <FormattedMessage id="error.button" />
              </Button>
            </a>
          </Link>
        </div>
      </NotFoundWrapper>
    </div>
  );
};

export default ErrorView;
